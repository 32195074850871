<template>
  <top-nav-theme0 v-if="kindTheme===0"/>
  <top-nav-theme1 v-else-if="kindTheme===1"/>
</template>

<script>

export default {
  name: 'TopNav',
  components: {
    TopNavTheme0: () => import('@/components/HeaderFooter/TopNav/TopNavTheme0'),
    TopNavTheme1: () => import('@/components/HeaderFooter/TopNav/TopNavTheme1'),
  },
  props: {
    kindTheme: {
      type: Number,
      default: 0,
    },
  },
};
</script>
